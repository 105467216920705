<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="assistant-bubble text-center">
    <v-icon class="bubble-icon" name="bi-stars" scale="1.75" fill="#6480cd" animation="pulse" hover="true"></v-icon>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script scoped>
import { nextTick } from "vue";
import * as $api from "../../../api/api";
import Modal from '../Modals/Modal.vue'
export default {
  /* Name */
  name: "AIAssistant",
  /* Data */
  data() {
    return {

    };
  },
  /* Components */
  components: {
    Modal
  },
  /* Props */
  props: {},
  /* Methods */
  methods: {
  },
  /* Computed */
  computed: {
  },
  async mounted() {
    //this.openModal();
  },
  /* Emits */
  emits: ["emits"],
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->

<style scoped>
.assistant-bubble{
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 2rem;
  margin-right: 2rem;
  background-color: aliceblue;
  z-index: 999;
  cursor: pointer;
  padding: 1rem;
}
.assistant-bubble:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;
}
.bubble-icon{
  vertical-align: middle;
  margin: auto;
}
</style>
