import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import NodeMenu from "../../components/Partials/IncidentInvestigation/NodeMenu.vue"
import { nextTick } from "vue";
import Timeline from "../../components/Partials/Timeline/Timeline.vue"
import OpenGroup from "../../components/Partials/OpenGroup/OpenGroup.vue"
import OrganizationChart from 'primevue/organizationchart';
import Chart from 'primevue/chart';
import { ref } from "vue";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'
import { MiniMap } from '@vue-flow/minimap'
import '@vue-flow/minimap/dist/style.css'
import { Controls } from '@vue-flow/controls'
import '@vue-flow/controls/dist/style.css'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            status: '',
            status_has_changed: false,
            close: true,
            locked: true,
            statuses: [
                { label: this.$t('new'), status: 'new', default_description: 'New incident detected' },
                { label: this.$t('active'), status: 'active', default_description: 'Incident is being investigated' },
                { label: this.$t('mitigated'), status: 'mitigated', default_description: 'Incident has been mitigated' },
                { label: this.$t('closed'), status: 'closed', default_description: 'Incident has been closed' },
            ],
            teams: [],
            details: {
                "title": "Modification of ld.so.preload",
                "description": "Identifies modification of ld.so.preload for shared object injection. This technique is used by attackers to load arbitrary code into processes.",
                "severity": 3,
                "created_at": "2022-11-22T02:34:37.641754+00:00",
                "status": [{
                    "status": "Created",
                    "description": "New event detected",
                    "user": "Detection System"
                }],
                "id": "74bff7e4-de1d-49cc-ab7b-11c184420812",
                "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9",
                "incidents": [{
                    "created_at": "2022-11-25T04:47:20.760329+00:00",
                    "status": [{
                        "status": "Created",
                        "description": "New event detected",
                        "user": "Correlation System"
                    }],
                    "description": "This rule detects rare scheduled task creations. Typically software gets installed on multiple systems and not only on a few. The aggregation and count function selects tasks with rare names.",
                    "id": "436582ee-b5de-4b49-8956-912bc9335c54",
                    "team_id": "d1a0f1aa-c548-403a-b1db-ebb701605c14",
                    "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9",
                    "title": "Rare Scheduled Task Creations",
                    "severity": 1,
                    "correlation_rule_id": "087b48f2-1948-447c-8218-744846aa2bc7"
                }
                ],
                "logs": [{
                    "id": "01d2f1a6-a544-4557-bc76-acae3cfc5445",
                    "asset_id": "b5905c12-360e-4697-983f-700fecb2d0a9",
                    "log": {
                        "asset": {
                            "hostname": "centos7-yipi",
                            "ip": [
                                "192.168.184.137",
                                "fe80::a018:9c41:a3be:f726",
                                "192.168.122.1",
                                "172.17.0.1"
                            ],
                            "mac": [
                                "00:0c:29:db:69:85",
                                "02:42:9d:9b:d9:fc",
                                "52:54:00:f5:a2:46"
                            ],
                            "os": "CentOS Linux",
                            "id": "4cdd6fe72a244ab98846069b1f809e9f",
                            "containerized": false
                        },
                        "source": "auditd_auditbeat",
                        "msg": {
                            "action": "executed",
                            "result": "success",
                            "object": "/usr/bin/ls",
                            "uname": "yipi",
                            "uid": 1000,
                            "pid": 24267,
                            "executable": "/usr/bin/ls",
                            "args": [
                                "ls",
                                "-l"
                            ],
                            "working_directory": "/home/yipi/Documentos/python3"
                        },
                        "date": 1664177451000,
                        "date_intake": 1664177451000
                    },
                    "date": "2022-10-14T14:35:46",
                    "source_id": "4107dd1d-771f-426a-8f5c-68d756c9bf68",
                    "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9"
                }
                ],
                "correlation_rule": {
                    "configuration": {
                        "selection": {
                            "type": "PATH",
                            "name": "/etc/ld.so.preload"
                        },
                        "condition": "selection"
                    },
                    "description": "Identifies modification of ld.so.preload for shared object injection. This technique is used by attackers to load arbitrary code into processes.",
                    "severity": 3,
                    "id": "e203463b-d863-4224-b878-3694ea6f04e6",
                    "created_at": "2022-11-25T09:37:16.821052+00:00",
                    "title": "Modification of ld.so.preload",
                    "author": "E.M. Anhaus (originally from Atomic Blue Detections, Tony Lambert), oscd.community",
                    "is_active": true,
                    "organization_id": "44635a68-8c64-48e7-beac-1e015f4a17d9"
                },
                "source": {
                    "id": "2230c02a-f286-4be8-88a9-bdd47ad9466f",
                    "name": "CrowdStrike Falcon"
                }
            },
            events: [{
                "title": "Console login failure",
                "description": "User louis@evil-corp.com failed first factor login to Admin Console",
                "severity": 0,
                "detection_rule": {
                    "id": "e85f1784-bf05-4142-9a41-9b731f53d85a",
                    "title": "Console login failure"
                },
                "created_at": "2022-11-09T11:39:01",
                "status": [{
                    "status": "Created",
                    "description": "New event detected.",
                    "user": "4ea905db-d246-4a33-9910-c6cc1d139bb9",
                    "date": "2022-11-09T11:39:01"
                }],
                "id": "4ea905db-d246-4a33-9910-c6cc1d139bb9",
                "organization_id": "9b3a8bb9-a643-4edf-b796-bf630a8cd833"
            }],
            incidents: [{
                "title": "Evil Office 1 - DDOS",
                "description": "A DDoS attack has been detected on three assets of Evil Office 1 at North Carolina",
                "severity": 1,
                "created_at": "2022-10-07 09:55:14",
                "status": [{
                    "status": "Working",
                    "description": "I've been investigating those strange logs. Nate, pls check this: https://nsfocusglobal.com/freakout-malware-analysis-freakout-samples/"
                }],
                "id": "4ea905db-d246-4a33-9910-c6cc1d139bb9",
                "team_id": "9b3a8bb9-a643-4edf-b796-bf630a8cd833",
                "correlation_rule": {
                    "id": "8944febb-2162-4d9d-b3f0-9fc89e16d3d9",
                    "title": "DDOS Attack"
                },
                "events": [
                    "4ea905db-d246-4a33-9910-c6cc1d139bb9"
                ]
            }],
            sources: [{
                "id": "a5f7f9d9-3229-4506-8cff-95bbfb9b6d64",
                "name": "Apache",
                "description": "Apache2 web server",
                "logo": "apache.png",
                "log_number": 100,
            }
            ],
            alerts: [{
                "name": "Alert SOC (Tier 1) when DDOS on production server",
                "description": "Alert SOC-Tier1 configuration is triggered when DDOS is detected on production server",
                "severity": 1,
                "filters": [
                    "DDoS",
                ],
                "users": [
                    "Gh0stpp7",
                ],
                "alertMethods": [{
                    "name": "Email",
                    "description": "Send email to the user",
                    "type": "email"
                },
                ],
                "id": "a5f7f9d9-3229-4506-8cff-95bbfb9b6d64",
                correlation_rules: [{
                    "id": "8944febb-2162-4d9d-b3f0-9fc89e16d3d9",
                    "name": "DDOS Attack",
                    severity: 4,
                    active: true,
                }],
                detection_rules: [{
                    "id": "e85f1784-bf05-4142-9a41-9b731f53d85a",
                    "name": "Console login failure",
                    severity: 0,
                    active: true,
                }]
            }],
            asset_groups: [{
                "description": "Development servers of the organization",
                "name": "Development Servers",
                "organization_id": "53f2b97d-3e6c-48de-8fa1-4983a4902fba",
                "tags": [
                    "Development",
                    "Server"
                ],
                "id": "0ca148e1-4051-4bc7-8bfe-86bdba3d1d86"
            }],
            loading_details: false,
            // Preguntar que tipos hay
            types: [
                { label: 'Server', value: 'Server' },
                { label: 'Router', value: 'Router' },
                { label: 'Switch', value: 'Switch' },
                { label: 'Firewall', value: 'Firewall' },
                { label: 'Workstation', value: 'Workstation' },
                { label: 'Printer', value: 'Printer' },
                { label: 'Other', value: 'Other' }
            ],
            criticalities: [
                { label: this.$t('low'), value: 1 },
                { label: this.$t('medium'), value: 2 },
                { label: this.$t('high'), value: 3 },
                { label: this.$t('critical'), value: 4 }
            ],
            separatorExp: (/,| /),
            pieData1: {
                labels: ['A', 'B', 'C', 'D', 'E'],
                datasets: [{
                    data: [540, 325, 702, 421, 256],
                    backgroundColor: ['#f28263', '#a3dfc9', '#ffd366', '#f6a437', '#b0b0b0'],
                }]
            },
            pieData2: {
                labels: ['A', 'B', 'C', 'D', 'E'],
                datasets: [{
                    data: [540, 325, 702, 421, 256],
                    backgroundColor: ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32'],
                }]
            },
            pieOptions: {
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            boxWidth: 15,
                            color: '#495057'
                        }
                    }
                }
            },
            barData: {
                labels: [this.$t('informational'), this.$t('low'), this.$t('medium'), this.$t('high'), this.$t('critical')],
                datasets: [{
                    backgroundColor: ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32'],
                    data: [65, 59, 80, 81, 56]
                },]
            },
            barOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            events_timeline: [],
            status_timeline: [],

            tab_position: 'left',
            editMode: false,
            filters: null,


            /* Investigate variables */
            flow_chart: [],
            isNodeMenuOpen: false,
            selectedNode: {},
            selectedNodeData: {},
            selectedNodeType: '',
            defaultZoom: 2,
            active_tab: 0,
            isNewNode: false,
            isNodeDetails: false,
            newNodeID: 0,
            isEdgeOptions: false,
            edgeObject: {},
            openOptions: false,
            nodeTypes: [
                'event',
                'incident',
            ],
        }

    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        OpenGroup,
        Chart,
        Timeline,
        OrganizationChart,
        NodeMenu,
        MiniMap,
        Controls
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatString (text, max_chars = 30) {
            // if str.length > 30 then return str.substring(0, 30) + "..."
            if (text.length > max_chars) {
                return text.substring(0, max_chars) + "..."
            }
            return text
        },
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        lockForm() {
            this.locked = true;
            let dropdowns = document.getElementsByClassName("dropdown-menu");
            for (let i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove("show");
            }
            /*
            let toggles = document.getElementsByClassName("dropdown-toggle")
            for (let i = 0; i < toggles.length; i++) {
                toggles[i].setAttribute("aria-expanded", 'false')
            }
            */
        },
        unlockForm() {
            this.locked = false;
        },
        onCancelUpdate() {
            // Reset form
            this.details.description = this.description;
            this.details.severity = this.severity;
            this.details.status = this.status_backup
            this.details.status[this.details.status.length - 1] = this.status
            this.lockForm();
        },
        onSaveUpdate() {
            let to_update_object = {}
            if (this.details.description != this.description) {
                to_update_object.description = this.details.description
            }
            if (this.details.severity != this.severity) {
                to_update_object.severity = this.details.severity
            }
            if (this.status_has_changed) {
                to_update_object.status = this.details.status[this.details.status.length - 1]
            }

            // Update asset via API and update local data
            $api.updateIncident(this.$route.query.id, to_update_object).then((response) => {
                //console.log(`[+] Updated incident: ${response.data}`);
                this.severity = this.details.severity;
                //this.status = this.details.status[this.details.status.length-1].status
                this.criticality = this.details.criticality;
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentUpdated'), life: 3000 });
                this.lockForm();
                //refresh page
                //this.$router.go();

            }).catch((err) => {
                console.log(`[-] Error (updateIncident): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotUpdated'), life: 3000 });
            });
        },
        addNewStatus(status) {
            this.details.status.push({
                "status": status.status,
                "description": status.default_description,
            });
            //console.log("NEW STATUS: ", this.details.status[this.details.status.length - 1]);

            this.status_has_changed = true;
        },
        updateTeam(id, team) {
            $api.updateIncident(id, { "team_id": team.id }).then((response) => {
                //console.log(`[+] Incident ${id} updated`);
                this.details.team = team;
                // toast
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('incidentUpdated'), life: 3000 });
            }).catch((err) => {
                console.log(`[-] Error (updateIncident): ${err}`);
                // toast
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('incidentNotUpdated'), life: 3000 });
            });;
        },
        calculateChartDatasets() {
            // get all details.events and calculate the number of events per severity
            let informational = 0;
            let low = 0;
            let medium = 0;
            let high = 0;
            let critical = 0;
            for (let i = 0; i < this.details.events.length; i++) {
                if (this.details.events[i].severity === 0) {
                    informational++;
                } else if (this.details.events[i].severity === 1) {
                    low++;
                } else if (this.details.events[i].severity === 2) {
                    medium++;
                } else if (this.details.events[i].severity === 3) {
                    high++;
                } else if (this.details.events[i].severity === 4) {
                    critical++;
                }
            }
            this.pieData2.labels = ['Informational', 'Low', 'Medium', 'High', 'Critical'];
            this.pieData2.datasets[0].data = [informational, low, medium, high, critical];

            // get all details.events and calculate the number of events per status
            let new_ = 0;
            let open = 0;
            let partial_mitigated = 0;
            let mitigated = 0;
            let closed = 0;
            for (let i = 0; i < this.details.events.length; i++) {
                if (this.details.events[i].status[this.details.events[i].status.length - 1].status == 'new') {
                    new_++;
                } else if (this.details.events[i].status[this.details.events[i].status.length - 1].status == 'active') {
                    open++;
                } else if (this.details.events[i].status[this.details.events[i].status.length - 1].status == 'partial_mitigated') {
                    partial_mitigated++;
                } else if (this.details.events[i].status[this.details.events[i].status.length - 1].status == 'mitigated') {
                    mitigated++;
                } else if (this.details.events[i].status[this.details.events[i].status.length - 1].status == 'closed') {
                    closed++;
                }
            }
            this.pieData1.labels = ['New', 'Active', 'Part. Mitigated', 'Mitigated', 'Closed'];
            this.pieData1.datasets[0].data = [new_, open, partial_mitigated, mitigated, closed];
        },
        generateTimelineData() {
            // format { dateLabel: 'January 2017', title: 'Gathering Information' }

            // get all events
            for (let i = 0; i < this.details.events.length; i++) {
                let date = new Date(this.details.events[i].created_at);
                let dateLabel = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                let title = this.details.events[i].title;
                let id = this.details.events[i].id;
                let severity = this.details.events[i].severity;
                this.events_timeline.push({ dateLabel, title, id, severity });
            }
            // sort events by date
            this.events_timeline.sort((a, b) => {
                return new Date(a.dateLabel) - new Date(b.dateLabel);
            });
        },
        generateStatusTimelineData() {
            // format { dateLabel: 'January 2017', title: 'Gathering Information' }
            // get all status
            for (let i = 0; i < this.details.status.length; i++) {
                let date = new Date(this.details.status[i].date);
                let dateLabel = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
                let title = this.details.status[i].status;
                let id = 0
                let severity = title
                this.status_timeline.push({ dateLabel, title, id, severity });
            }
            // sort status by date
            this.status_timeline.sort((a, b) => {
                return new Date(a.dateLabel) - new Date(b.dateLabel);
            });
        },


        /* ------------------------------------- Investigate functions  -------------------------------------*/
        calculateFlowChartData() {
            let node_id = 1;
            let edge_id = 1;
            let x = 125;
            let y = 25;
            // get color in function of severity
            let color = '#ffcc00';
            switch (this.details.severity) {
                case 1:
                    color = '#6dc193';
                    break;
                case 2:
                    color = '#f7b34c';
                    break;
                case 3:
                    color = '#ef6154';
                    break;
                case 4:
                    color = '#b83c32';
                    break;
                default:
                    color = '#aeb5c1';
            }

            // create input node
            this.flow_chart = [
                // Nodes
                { id: this.details.id, type: 'incident', label: this.details.title, position: { x: 20, y: 100 }, 
                data: {
                    'type': 'incident',
                    'object': this.details,
                    // Styles
                    'icon_color': color,
                    'handles': [false, true, false, false],
                    'warning': false,
                    'selected': false,
                } },
            ];
            this.selectedNode = this.details;
            this.selectedNodeData = this.flow_chart[0].data;
            
            //this.isNodeDetails = true;
            // loop events and create nodes
            for (let i = 0; i < this.details.events.length; i++) {
                switch (this.details.events[i].severity) {
                    case 1:
                        color = '#6dc193';
                        break;
                    case 2:
                        color = '#f7b34c';
                        break;
                    case 3:
                        color = '#ef6154';
                        break;
                    case 4:
                        color = '#b83c32';
                        break;
                    default:
                        color = '#aeb5c1';
                }
                let defaultlinkposition = '';
                // create node
                this.flow_chart.push({
                    type: 'event',
                    id: defaultlinkposition+this.details.events[i].id,
                    label: this.details.events[i].title,
                    position: { x: x, y: y },
                    data: {
                        'type': 'event',
                        'object': this.details.events[i],
                        // Styles
                        'icon_color': color,
                        'handles': [false, false, false, true],
                        'warning': false,
                        'warning_description': '',
                        'selected': false,
                    },
                    //events: {nodeClick: this.onNodeClick(this.details.events[i].id, 'event')}
                });

                // create edges: incident -> event
                this.flow_chart.push({
                    id: 'e' + edge_id.toString(),
                    source: this.details.id,
                    target: this.details.events[i].id,
                    sourceHandle: 'right-handle',
                    targetHandle: 'left-handle',
                    label: '',
                    updatable: true,
                    // Style
                    animated: false,
                    style: { 'stroke': color }, // Set to variable 'color' for edges with different colors, else set to 'B0B0B0' 
                });
                edge_id++;
                node_id++;
                y += 75;
            }
            // find first edge element
            let index = this.flow_chart.findIndex(x => x.id === 'e1');
            this.edgeObject = this.flow_chart[index];

            // find id = 0 and set position to half of the events
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].id === this.details.id) {
                    this.flow_chart[i].position.y = (y / 2) - 25;
                }
            }

        },
/* ------------------------------------- Handlers functions  -------------------------------------*/
        onNodeDragStart(event) {
        },
        onNodeDrag(event) {
        },
        onNodeDragStop(event) {
        },
        onNodeClick(event) {
            
            // Set selectedNode
            this.selectedNode = event.node;
            this.selectedNodeData = event.node.data;
            this.selectedNodeType = event.node.type;

            this.isNodeDetails = true;
            this.isEdgeOptions = false;
            
            // find node and set selected true
            this.focusSelectedNode();
        },
        onConnectCustom(event){
            console.log("onConnectCustom");
            console.log(this.flow_chart);
        },
        onEdgeClick(event) {
            // find edge and set animated true
            let index = this.flow_chart.findIndex(x => x.id === event.edge.id);
            this.edgeObject = this.flow_chart[index];
            this.focusSelectedEdge();
            
            this.isEdgeOptions = true;
            this.edgeObject = this.flow_chart[index]; 
        },
        onEdgeDoubleClick(event) {
            // Delete edge from flow_chart
            let index = this.flow_chart.findIndex(x => x.id === event.edge.id);
            this.flow_chart.splice(index, 1);
        },
        /* ------------------------------------- Aux functions  -------------------------------------*/
        checkIfValidSourcePos(sourceHandlePosition, targetHandlePosition) {

        },
        focusSelectedNode() {
            //console.log("focusSelectedNode");
            //set all edeges to animated false
            this.unfocusSelectedNode();
            // find edge and set selected true
            let index = this.flow_chart.findIndex(x => x.id === this.selectedNode.id);
            this.flow_chart[index].data.selected = true;
        },
        unfocusSelectedNode(){
            //console.log("unfocusSelectedNode");
            //set all edeges to selected false
            for (let i = 0; i < this.flow_chart.length; i++) {
                this.flow_chart[i].data.selected = false;
            }
        },
        focusSelectedEdge() {
            //console.log("focusSelectedEdge");
            //set all edeges to animated false
            this.unfocusSelectedEdge();
            // find edge and set animated true
            let index = this.flow_chart.findIndex(x => x.id === this.edgeObject.id);
            this.flow_chart[index].animated = true;
        },
        unfocusSelectedEdge(){
            //console.log("unfocusSelectedEdge");
            //set all edeges to animated false
            for (let i = 0; i < this.flow_chart.length; i++) {
                this.flow_chart[i].animated = false;
            }
        },
        deleteNode(id) {
            // Delete node from flow_chart
            let index = this.flow_chart.findIndex(x => x.id === id);
            this.flow_chart.splice(index, 1);
            // Delete edges from flow_chart
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].source === id || this.flow_chart[i].target === id) {
                    this.flow_chart.splice(i, 1);
                    i--;
                }
            }
            // Close node details
            this.isNodeDetails = false;
        },
        exploreNode(id, type) {
            this.$router.push({path: type+'-details', query:{id: id}})
        },
        getLastPositionY() {
            let y = 0;
            for (let i = 0; i < this.flow_chart.length-1; i++) {
                if (this.flow_chart[i].position != undefined){
                    if (this.flow_chart[i].position.y > y) {
                        y = this.flow_chart[i].position.y;
                    }
                }
            }
            return y;
        },
        
        newNode(type) {
            this.isNewNode = true;
            this.isNodeDetails = false;
            this.selectedNode = {};
            this.selectedNodeData = {};
            this.selectedNodeType = type;

            // add new node to flow_chart
            //let x = 125;
           // let y = this.getLastPositionY() + 75;

            let x = 25;
            let y = 25;


            let color = '#ffcc00';
            switch (this.details.severity) {
                case 1:
                    color = '#6dc193';
                    break;
                case 2:
                    color = '#f7b34c';
                    break;
                case 3:
                    color = '#ef6154';
                    break;
                case 4:
                    color = '#b83c32';
                    break;
                default:
                    color = '#aeb5c1';
            }
            this.flow_chart.push({
                type: type,
                id: ++this.newNodeID,
                label: 'New',
                position: { x: x, y: y },
                data: {
                    'type': type,
                    'object': {},
                    // Styles
                    'icon_color': color,
                    'handles': [false, false, false, true],
                    'warning': false,
                    'warning_description': '',
                },
            });
        },

        updateNodeColor(color) {
            // find node and update selectedNode
            let index = this.flow_chart.findIndex(x => x.id === this.selectedNode.id);

            // Update color
            this.flow_chart[index].data.icon_color = '#' + color;

        },
        updatedNodeType(type) {
            // find node and update selectedNode
            let index = this.flow_chart.findIndex(x => x.id === this.selectedNode.id);

            // Update type
            this.flow_chart[index].type = type;

            //console.log("flow_chart: ", this.flow_chart);
        },
        updateEdge() {
            //console.log("updateEdge: ", this.edgeObject);

            let index = this.flow_chart.findIndex(x => x.id === this.edgeObject.id);

            // Update edge
            this.flow_chart[index].style = {stroke: this.edgeObject.style.stroke};
            this.flow_chart[index].label = this.edgeObject.label;

            //console.log("flow_chart: ", this.flow_chart);
        },
        testFunction() {
        },
        focusFirstEdge() {
            // find first edge with id containing 'vueflow__edge'
            let index = this.flow_chart.findIndex(x => x.id.includes('vueflow__edge'));
            this.edgeObject = this.flow_chart[index];
            //this.focusSelectedEdge();
            //this.isEdgeOptions = true;
            this.edgeObject = this.flow_chart[index];
        },
        focusFirstNode() {
            this.selectedNode = this.flow_chart[0];
            this.selectedNodeData = this.flow_chart[0].data;
            this.selectedNodeType = this.flow_chart[0].type;

            // if event.node.data.type == 'event' get incidents
            if (this.flow_chart[0].data.type == 'event') {
                $api.getOneEvent(this.flow_chart[0].data.object.id, true).then((response) => {
                    this.selectedNodeData.object.incidents = response.incidents;
                }).catch((error) => {
                    console.log('[-] Error on getAllEvents: ', error);
                });
            }

            this.isNodeDetails = true;
            this.isEdgeOptions = false;

            // find node and set selected true
            this.focusSelectedNode();
        },

    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject; // get sources from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.data = require('./events.json'));
            //await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_details = true;
            
            $api.getOneIncident(this.$route.query.id, true).then((response) => {
                this.details = response;
                //console.log("INCIDENT: ", this.details);
                this.description = this.details.description;
                this.status = this.details.status[this.details.status.length - 1]
                this.status_backup = this.details.status
                this.severity = this.details.severity;

                if (this.details.events.length == 0) {
                    this.no_events = true;
                }
                
                this.loading_details = false;
                // calculate chart data
                this.calculateChartDatasets();
                this.generateTimelineData();
                this.generateStatusTimelineData();


                // calculate flow_chart data
                this.calculateFlowChartData();

                this.focusFirstNode();
                this.focusFirstEdge();
                

            }).catch((err) => {
                console.log(`[-] Error (getOneIncident): ${err}`);
            });

            // get teams from API
            if (process.env.VUE_APP_ENV === 'development') {
                //await (this.teams = require('./teams.json'));
            } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
                $api.getAllTeams(false).then((response) => {
                    this.teams = response;
                }).catch((err) => {
                    console.log(`[-] Error (getAllTeams): ${err}`);
                });
            } else {
                console.log("ERROR: NO ENVIRONMENT SELECTED");
            }
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }
    }
};
export default MyShared;