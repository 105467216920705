import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import OrganizationChart from 'primevue/organizationchart';
import { nextTick } from "vue";
import * as $api from "../../api/api";
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const confirm = useConfirm();
        return { confirm };
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            update_source_permission: false,
            update_listener_permission: false,
            delete_listener_permission: false,
            create_listener_permission: false,
            get_listener_permission: false,
            get_intake_method_permission: false,

            loading_table: false,
            show_params_error: false,

            geopip_checked: false,
            threatintel_checked: false,
            airsiem_checked: false,

            flow_chart: [],

            listener_name: '',
            listener_desc: '',
            listener_intake_method: null,
            selectedIntakeMethod: {},
            selectedIntakeMethods: [],
            activeMethods: [],
            intake_methods: [],
            new_schema_params_array: [],
            new_schema_params: {},

            valid_schemas: [],
            listener_intake_schema: null,
            needNewIntakeSchema: false,
            formats: [
                { name: this.$t('json'), code: 'json' },
                { name: this.$t('xml'), code: 'xml' },
                { name: this.$t('csv'), code: 'csv' },
                { name: this.$t('raw'), code: 'raw' },
            ],
            new_intake_format: null,

            // INTAKE SCHEMA CONFIG
            new_intake_name: '',
            new_intake_desc: '',
            new_intake_schema_params: {},
            new_intake_schema_params_array: [],
            new_schema_dst: '',
            new_schema_src: '',
            new_schema_src_type: null,
            new_schema_dst_type: null,
            new_schema_required: false,
            configmenu: false,

            events: [
                { status: '1', },
                { status: '2', },
                { status: '3', },
                { status: '4', }
            ],
            noSelectedIntakeMethods: false,
            steps: 0,
            stepChecker: 0,
            showError: false,
            showDelete: false,

            nifi_logo: require('@/assets/nifi.svg'),
            preprocessor_logo: require('@/assets/preprocessor.png'),
            geo_ip_logo: require('@/assets/geo-ip.png'),
            threat_intel_logo: require('@/assets/threat-intel.jpg'),
            deepSiem_logo: require('@/assets/logo.svg'),
            elastic_logo: require('@/assets/elastic.png'),
            newPicture: null,
            uploadedFiles: [],
            data: {
                "name": "",
                "intake_methods": [],
                "listeners": [],
            },

            temp_name: '',
            temp_desc: '',
            temp_logo: '',

            selectedType: null,
            types: [
                { name: this.$t('integer'), code: 'int' },
                { name: this.$t('string'), code: 'str' },
                { name: this.$t('boolean'), code: 'boolean' },
            ],
            toggleIntakeConfig: false,
            //intakeMethods: [],

            organizationChart: {
                key: '0',
                data: { label: 'AWS Network Firewall' },
                children: [{
                    key: '0_0_0',
                    data: { label: 'Auditbeat' },
                    children: [{
                            key: '0_1_0',
                            data: { label: 'Port: 5044' }
                        },
                        {
                            key: '0_1_1',
                            data: { label: 'Max Size of Message Queue: 20000' }
                        },
                        {
                            key: '0_1_2',
                            data: { label: 'Max Number of TCP Connections: 5' }
                        }
                    ]
                }]
            },
            createNewIntegrationStep: 0,
            createNewIntegrationStepTitle: this.$t('createNewIntegration'),
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog,
        OrganizationChart,

    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        formatParams() {
            if (this.new_schema_params == undefined || this.new_schema_params == null)
                return;

            // get the keys of the object
            let keys = Object.keys(this.data.intake_methods);

            if (keys == undefined || keys == null)
                return;

            // loop through the keys
            for (let i = 0; i < keys.length; i++) {
                // use the key for the name
                let aux = {
                        "name": keys[i],
                        "description": this.new_schema_params[keys[i]].description,
                        "type": this.new_schema_params[keys[i]].type
                    }
                    // add the object to the array if it doesn't exist one with the same name
                if (!this.new_schema_params_array.some(e => e.name === aux.name))
                    this.new_schema_params_array.push(aux);
                //console.log(this.new_schema_params_array);
            }
        },
        getSelectedIntakeSchemasForSelectedIntakeMethod(intake) {
            $api.getOneIntakeMethod(intake.id, true).then((response) => {
                this.selectedIntakeMethod = response;
            }).catch((err) => {
                console.log(`[-] Error (getOneIntakeMethod): ${err}`);
            });
        },
        addNewIntakeMethod(name, desc, nifi) {
            if (Object.keys(this.new_schema_params).length === 0 && this.new_schema_params.constructor === Object) {
                this.show_params_error = true;
                return;
            }
            $api.createIntakeMethod({
                "description": desc,
                "name": name,
                "nifi_listener": nifi,
                "configuration_schema": this.new_schema_params
            }).then((response) => {

                $api.getAllIntakeMethods(false).then((response) => {
                    this.intake_methods = response;
                    this.selectedIntakeMethods = response;
                    //console.log("INTAKE METHODS: ", response);
                }).catch((err) => {
                    console.log(`[-] Error (getAllIntakeMethods): ${err}`);
                });

                //toast 
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('intakeMethodCreated'), life: 3000 });
                this.$refs.newIntakeMethod.closeModal();

            }).catch((error) => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: error.response.data.detail, life: 3000 });
            });
        },
        addNewParamToIntakeMethodConf(name, desc, type) {
            // check if the name is already in the object
            if (this.new_schema_params.hasOwnProperty(name)) {
                // if it is, delete it
                delete this.new_schema_params[name];
            }

            // use the name as key
            this.new_schema_params[name] = {
                "type": type.code,
                "description": desc
            };
            //console.log(this.new_schema_params);

            // get new_schema_params keys
            let keys = Object.keys(this.new_schema_params);

            if (keys == undefined || keys == null)
                return;

            // loop through the keys
            for (let i = 0; i < keys.length; i++) {
                // use the key for the name
                let aux = {
                        "name": keys[i],
                        "description": this.new_schema_params[keys[i]].description,
                        "type": this.new_schema_params[keys[i]].type
                    }
                    // add the object to the array if it doesn't exist one with the same name
                if (!this.new_schema_params_array.some(e => e.name === aux.name)){
                    this.new_schema_params_array.push(aux);
                    // clear inputs
                    this.new_param_name = '';
                    this.new_param_desc = '';
                    this.selectedType = null;
                }
                    
                //console.log(this.new_schema_params_array);
            }
        },
        deleteParamFromIntakeMethodConf(name) {
            // delete the param from the object
            delete this.new_schema_params[name];
            // find name in array and delete it
            this.new_schema_params_array = this.new_schema_params_array.filter(function(obj) {
                return obj.name !== name;
            });
        },
        addNewIntakeSchema(name, desc, format) {
            if (Object.keys(this.new_intake_schema_params).length === 0 && this.new_intake_schema_params.constructor === Object) {
                this.show_params_error = true;
                return;
            }
            $api.createIntakeSchema({
                "description": desc,
                "name": name,
                "intake_schema": {
                    "source": this.data.name,
                    "format": format.code,
                    "mapping": this.new_intake_schema_params
                },
                "intake_method_id": this.listener_intake_method.id,
                "source_id": this.data.id

            }).then((response) => {

                $api.getIntakeSchemasBy(`{"source_id": "${this.data.id}","intake_method_id": "${this.listener_intake_method.id}"}`).then((response) => {
                    this.intake_schemas = response;
                    //console.log("INTAKE SCHEMAS: ", this.intake_schemas);
                    if (this.intake_schemas.length === 0) {
                        this.needNewIntakeSchema = true;
                        this.steps = 3;
                    } else {
                        this.needNewIntakeSchema = false;
                        this.steps = 3;
                    }
                    this.$refs.newIntakeSchema.closeModal();
                }).catch((err) => {
                    console.log(`[-] Error (getIntakeSchemasBy): ${err}`);
                });

                //toast 
                this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('intakeMethodCreated'), life: 3000 });


            }).catch((error) => {
                //console.log(error);
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: error.response.data.detail, life: 3000 });
            });
        },
        addNewParamToIntakeSchemaConf(new_schema_dst, new_schema_src, new_schema_src_type, new_schema_dst_type, new_schema_required) {
            // check if the name is already in the object
            if (this.new_intake_schema_params.hasOwnProperty(new_schema_dst)) {
                // if it is, delete it
                delete this.new_intake_schema_params[new_schema_dst];
            }

            // use the name as key
            this.new_intake_schema_params[new_schema_dst] = {
                "src": new_schema_src,
                "src_type": new_schema_src_type.code,
                "dst_type": new_schema_dst_type.code,
                "required": new_schema_required
            };

            // get new_intake_schema_params keys
            let keys = Object.keys(this.new_intake_schema_params);

            if (keys == undefined || keys == null)
                return;

            // loop through the keys
            for (let i = 0; i < keys.length; i++) {
                // use the key for the name
                let aux = {
                    "dst": keys[i],
                    "src": this.new_intake_schema_params[keys[i]].src,
                    "src_type": this.new_intake_schema_params[keys[i]].src_type.code,
                    "dst_type": this.new_intake_schema_params[keys[i]].dst_type.code,
                    "required": this.new_intake_schema_params[keys[i]].required
                }

                // add the object to the array if it doesn't exist one with the same name
                if (!this.new_intake_schema_params_array.some(e => e.dst === aux.dst)) {
                    this.new_intake_schema_params_array.push(aux);
                }

            }
        },
        deleteParamFromIntakeSchemaConf(name) {
            // delete the param from the object
            delete this.new_intake_schema_params[name];
            // find name in array and delete it
            this.new_intake_schema_params_array = this.new_intake_schema_params_array.filter(function(obj) {
                return obj.dst !== name;
            });;
        },
        checkChar(letter) {
            // check if letter is between a-i or A-I
            if ((letter >= 'a' && letter <= 'i') || (letter >= 'A' && letter <= 'I')) {
                return 'default-img-v1';
            }
            // check if letter is between j-r or J-R
            if ((letter >= 'j' && letter <= 'r') || (letter >= 'J' && letter <= 'R')) {
                return 'default-img-v2';
            }
            // check if letter is between s-z or S-Z
            if ((letter >= 's' && letter <= 'z') || (letter >= 'S' && letter <= 'Z')) {
                return 'default-img-v3';
            }
        },

        updateNewIntegration(name, desc) {
            //add integration to source list
            $api.updateSource(this.data.id, { "name": name, "description": desc }).then(response => {
                this.data.name = name;
                this.data.description = desc;
                if (this.newPicture != null)
                    this.updatePicture(response.id, this.newPicture)
                this.$refs.editIntegration.closeModal();
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('integrationUpdated'), life: 3000 });
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('integrationNotUpdated'), life: 3000 });
            });
        },
        updateSelectedIntakeMethods(id) {
            // find name in selectedIntakeMethods and set active to !active
            for (let i = 0; i < this.selectedIntakeMethods.length; i++) {
                if (this.selectedIntakeMethods[i].id === id) {
                    this.selectedIntakeMethods[i].active = !this.selectedIntakeMethods[i].active;
                }
            }
            this.data.intake_methods = this.selectedIntakeMethods

            // find active methods in selectedIntakeMethods and add them to activeMethods
            this.activeMethods = []
            for (let i = 0; i < this.selectedIntakeMethods.length; i++) {
                if (this.selectedIntakeMethods[i].active === true) {
                    this.activeMethods.push(this.selectedIntakeMethods[i])
                }
            }

            let active_methods_ids = []
            for (let i = 0; i < this.activeMethods.length; i++) {
                active_methods_ids.push(this.activeMethods[i].id)
            }

            $api.updateSource(this.data.id, { "intake_methods": active_methods_ids }).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('integrationUpdated'), life: 3000 });
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('integrationNotUpdated'), life: 3000 });
            });

            // if none is active set noSelectedIntakeMethods to true
            if (!this.selectedIntakeMethods.some(e => e.active === true)) {
                this.noSelectedIntakeMethods = true
                return
            }
            // if some is active set noSelectedIntakeMethods to false
            if (this.selectedIntakeMethods.some(e => e.active === true)) {
                this.noSelectedIntakeMethods = false
                return
            }
        },
        setSelectedIntakeMethods() {
            this.selectedIntakeMethods = this.intake_methods

            // loop through the intake methods
            for (let i = 0; i < this.intake_methods.length; i++) {
                // if this.intake_methods[i] is in data.intake_methods
                if (this.data.intake_methods.some(e => e.name === this.intake_methods[i].name)) {
                    this.selectedIntakeMethods[i].active = true
                } else {
                    this.selectedIntakeMethods[i].active = false
                }
            }
            // if none is active set noSelectedIntakeMethods to true
            if (!this.selectedIntakeMethods.some(e => e.active === true)) {
                this.noSelectedIntakeMethods = true
            }

            // find active methods in selectedIntakeMethods and add them to activeMethods
            for (let i = 0; i < this.selectedIntakeMethods.length; i++) {
                if (this.selectedIntakeMethods[i].active === true) {
                    this.activeMethods.push(this.selectedIntakeMethods[i])
                }
            }
        },
        pause(id) {
            this.$confirm.require({
                message: this.$t('pauseConfirmation'),
                header: this.$t('confirmation'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    $api.updateListener(id, { "is_active": false }).then(response => {
                        // find name in selectedIntakeMethods and set active to !active
                        for (let i = 0; i < this.data.listeners.length; i++) {
                            if (this.data.listeners[i].id === id) {
                                this.data.listeners[i].is_active = false;
                            }
                        }
                        document.getElementById('switch_' + id).classList.remove('p-inputswitch-checked');
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('listenerUpdated'), life: 3000 });
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('listenerNotUpdated'), life: 3000 });
                    });
                },
                reject: () => {
                    document.getElementById('switch_' + id).classList.add('p-inputswitch-checked');
                },
            });
        },
        play(id) {
            this.$confirm.require({
                message: this.$t('playConfirmation'),
                header: this.$t('confirmation'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    $api.updateListener(id, { "is_active": true }).then(response => {
                        // find name in selectedIntakeMethods and set active to !active
                        for (let i = 0; i < this.data.listeners.length; i++) {
                            if (this.data.listeners[i].id === id) {
                                this.data.listeners[i].is_active = true;
                            }
                        }
                        document.getElementById('switch_' + id).classList.add('p-inputswitch-checked');
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('listenerUpdated'), life: 3000 });
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('listenerNotUpdated'), life: 3000 });
                    });
                },
                reject: () => {
                    document.getElementById('switch_' + id).classList.remove('p-inputswitch-checked');
                },
            });
        },
        createListener(listener_name, listener_desc, configuration_schema) {
            // add listener to listeners list
            let new_listener = {
                "name": listener_name,
                "description": listener_desc,
                "configuration": {},
                "active": true,
                "intake_schema_id": this.listener_intake_schema.id
            };

            // loop through the configuration_schema
            for (let param in configuration_schema) {
                new_listener.configuration[param] = configuration_schema[param].value;
            }

            this.data.listeners.push(new_listener);

            $api.createListener(new_listener).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('listenerCreated'), life: 3000 });
                $api.getOneSource(this.$route.query.id, true).then((response) => {
                    this.data = response;
                    //console.log("SOURCE: ", this.data);
                    this.configmenu = false;
                }).catch((err) => {
                    console.log(`[-] Error (getOneSource): ${err}`);
                });
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: error.response.data.detail, life: 3000 });
            });

        },
        deleteListener(id) {
            this.$confirm.require({
                message: this.$t('deleteConfirmation'),
                header: this.$t('confirmation'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    $api.deleteListener(id).then(response => {
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('listenerDeleted'), life: 3000 });
                        // find name in data.listeners and delete it
                        for (let i = 0; i < this.data.listeners.length; i++) {
                            if (this.data.listeners[i].id === id) {
                                this.data.listeners.splice(i, 1);
                            }
                        }
                    }).catch(error => {
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: error.response.data.detail, life: 3000 });
                    });
                },
                reject: () => {},
            });
        },
        async loadListenerForm() {
            this.steps = 0
            await nextTick();
            this.steps = 1;
            await nextTick();
            this.steps = 0;
            await nextTick();
        },
        updateFlowChartIntakeMethod() {
            // loop flowchart and find id e1-2 in flowchart and set label to listener_intake_method
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].id === "e1-2") {
                    this.flow_chart[i].label = this.listener_intake_method.name;
                }
            }
            //console.log(this.flow_chart);
        },
        changeStep(num) {
            switch (num) {
                case 0:
                    this.steps = 0;
                    break;
                case 1:
                    if ((this.listener_name === null || this.listener_name === "") && (this.listener_desc === null || this.listener_desc === "")) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('error'),
                            detail: this.$t('enterName'),

                            life: 3000,
                        });
                        return;
                    } else {
                        this.steps = 1;
                    }
                    break;
                case 2:
                    if (this.listener_intake_method === null) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('error'),
                            detail: this.$t('selectIntakeMethod'),
                            life: 3000,
                        });
                        return;
                    } else {
                        // loop listener_intake_method.configuration_schema and set value to default value
                        for (let param in this.listener_intake_method.configuration_schema) {
                            this.listener_intake_method.configuration_schema[param].value = this.listener_intake_method.configuration_schema[param].default;
                        }

                        this.steps = 2;
                    }
                    break;
                case 3:
                    //loop listener_intake_method.configuration_schema and check if all values are set
                    for (let param in this.listener_intake_method.configuration_schema) {
                        if (this.listener_intake_method.configuration_schema[param].value === null || this.listener_intake_method.configuration_schema[param].value === "" || this.listener_intake_method.configuration_schema[param].value === undefined) {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('error'),
                                detail: this.$t('allParamsRequired'),
                                life: 3000,
                            });
                            return;
                        }
                    }
                    if (this.listener_intake_method === null) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('error'),
                            detail: this.$t('selectIntakeMethod'),
                            life: 3000,
                        });
                        return;
                    } else {
                        //console.log("SOURCE ID: ", this.data.id);
                        //console.log("INTAKE METHOD ID: ", this.listener_intake_method.id);
                        let by = {
                            "source_id": this.data.id,
                            "intake_method_id": this.listener_intake_method.id
                        }
                        //console.log("BY: ", by);
                        // llamar a la api para obtener los schemas validos para el intake method
                        $api.getIntakeSchemasBy(`{"source_id": "${this.data.id}","intake_method_id": "${this.listener_intake_method.id}"}`).then((response) => {
                            this.intake_schemas = response;
                            //console.log("INTAKE SCHEMAS: ", this.intake_schemas);
                            if (this.intake_schemas.length === 0) {
                                this.needNewIntakeSchema = true;
                                this.steps = 3;
                            } else {
                                this.steps = 3;
                            }

                        }).catch((err) => {
                            console.log(`[-] Error (getIntakeSchemasBy): ${err}`);
                        });

                    }
                    break;
                case 4:
                    if (this.listener_intake_schema === null) {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('error'),
                            detail: this.$t('selectIntakeSchema'),
                            life: 3000,
                        });
                        return;
                    } else {
                        this.steps = 4;
                    }
                    break;
            }
        },
        drag() {
            this.showDelete = true;
        },
        updateAnimation() {
            // loop flowchart and find id e7-6 in flowchart and set animated to airsiem_checked
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].id === "e7-6") {
                    this.flow_chart[i].animated = this.airsiem_checked;
                }
            }
            // loop flowchart and find id e4-3 in flowchart and set animated to airsiem_checked
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].id === "e4-3") {
                    this.flow_chart[i].animated = this.geopip_checked;
                }
            }
            // loop flowchart and find id e5-3 in flowchart and set animated to airsiem_checked
            for (let i = 0; i < this.flow_chart.length; i++) {
                if (this.flow_chart[i].id === "e5-3") {
                    this.flow_chart[i].animated = this.threatintel_checked;
                }
            }
        },
        /* ====================================== Picture ====================================== */
        getSourceLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        onAdvancedUpload(event) {
            let files = event.files[0]
            //console.log("FILES: ", files);

            let data = new FormData()
            data.append('in_file', files)
            //console.log("DATA: ", data);

            this.newPicture = data

            //this.updatePicture(data)
        },
        updatePicture(id, result) {
            $api.newSourceLogo(id, result).then(
                response => {
                    $api.getOneSource(this.$route.query.id, true).then((response) => {
                        this.data = response;
                        //console.log("SOURCE: ", this.data);

                    }).catch((err) => {
                        console.log(`[-] Error (getOneSource): ${err}`);
                    });
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('pictureAdded'), life: 3000 });
                }
            ).catch((err) => {
                console.log(`[-] Error (updateMe): ${err}`);
            })
        },
        stepFront() {
            this.createNewIntegrationStep++;
            if (this.createNewIntegrationStep === 0) {
                this.createNewIntegrationStepTitle = '1. ' + this.$t('createNewIntegration');
            } else if (this.createNewIntegrationStep === 1) {
                this.createNewIntegrationStepTitle = '2. ' + this.$t('selectIntakeMethods');
            }
        },
        stepBack() {
            this.createNewIntegrationStep--;
            if (this.createNewIntegrationStep === 0) {
                this.createNewIntegrationStepTitle = '1. ' + this.$t('createNewIntegration');
            } else if (this.createNewIntegrationStep === 1) {
                this.createNewIntegrationStepTitle = '2. ' + this.$t('selectIntakeMethods');
            }
        }
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        // get sources from API
        if (process.env.VUE_APP_ENV === 'development') {
            //await (this.data = require('./events.json'));
            //await (this.data_backup = require('./events.json'));
        } else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            this.loading_table = true;
            
            $api.getOneSource(this.$route.query.id, true).then((response) => {
                this.data = response;
                //console.log("SOURCE: ", this.data);
                if (this.hasUserPermissionGlobal(this.user, 'intake_method:get')) {
                    $api.getAllIntakeMethods(false).then((response) => {
                        this.intake_methods = response;
                        //console.log("INTAKE METHODS: ", response);
                        //this.intakeMethods = this.intakeMethods.concat(this.data.intake_methods);
                        this.temp_name = this.data.name;
                        this.temp_desc = this.data.description;
                        this.temp_logo = this.data.logo;
                        this.setSelectedIntakeMethods();
                        this.loading_table = false;

                    }).catch((err) => {
                        console.log(`[-] Error (getAllIntakeMethods): ${err}`);
                    });
                }

            }).catch((err) => {
                console.log(`[-] Error (getOneSource): ${err}`);
            });
        } else {
            console.log("ERROR: NO ENVIRONMENT SELECTED");
        }

        this.flow_chart = [
            { id: '1', type: 'integration', label: this.data.name, dragging: false, position: { x: 150, y: 5 } },
            { id: '2', type: 'nifi', label: this.$t('nifiListener'), dragging: false, position: { x: 175, y: 125 } },
            { id: '3', type: 'preprocessor', label: this.$t('preprocessor'), dragging: false, position: { x: 167, y: 210 } },
            { id: '4', type: 'geo_ip', label: this.$t('geo_ip'), dragging: false, position: { x: 50, y: 210 } },
            { id: '5', type: 'threat_intel', label: this.$t('threat_intel'), dragging: false, position: { x: 300, y: 210 } },
            { id: '6', type: 'output_index', label: this.$t('output_index'), dragging: false, position: { x: 170, y: 310 } },
            { id: '7', type: 'deepSiem_ia', label: this.$t('deep_siem_ia'), dragging: false, position: { x: 300, y: 305 } },
            // An animated edge
            { id: 'e1-2', label: 'intake_method', source: '1', target: '2', animated: true, markerEnd: 'arrow' },
            { id: 'e2-3', source: '2', target: '3', animated: true, markerEnd: 'arrow', targetHandle: 'a' },
            { id: 'e4-3', source: '4', target: '3', animated: false, markerEnd: 'arrow', targetHandle: 'b' },
            { id: 'e5-3', source: '5', target: '3', animated: false, markerEnd: 'arrow', targetHandle: 'c' },
            { id: 'e3-6', source: '3', target: '6', animated: true, markerEnd: 'arrow', sourceHandle: 'd' },
            { id: 'e7-6', source: '7', target: '6', animated: false, markerEnd: 'arrow', targetHandle: 'b' },
        ]

        // get PERMISSIONS
        this.update_source_permission = this.hasUserPermissionGlobal(this.user, 'source:update')

        this.update_listener_permission = this.hasUserPermissionGlobal(this.user, 'listener:update')
        this.delete_listener_permission = this.hasUserPermissionGlobal(this.user, 'listener:delete')
        this.create_listener_permission = this.hasUserPermissionGlobal(this.user, 'listener:create')
        this.get_listener_permission = this.hasUserPermissionGlobal(this.user, 'listener:get')

        this.get_intake_method_permission = this.hasUserPermissionGlobal(this.user, 'intake_method:get')

    }
};
export default MyShared;