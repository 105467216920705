<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <Navbar v-if="showNavbar && mq.current === 'desktop'"></Navbar>
  <NavbarMobile v-if="showNavbar && mq.current === 'mobile'"></NavbarMobile>
  <NavbarTablet v-if="showNavbar && mq.current === 'tablet'"></NavbarTablet>
  <Toast />
  <router-view :key="$route.fullPath" v-slot="{ Component, route }">
    <Transition name="fade" mode="out-in">
      <div :key="route.path">
        <component :is="Component"></component>
      </div>
    </Transition>
  </router-view>
  <!--<Footer v-if="showFooter"></Footer> -->
  <AIAssistant></AIAssistant>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->
<script>

import Sidebar from "./components/Partials/Sidebar/SideBar";
import Navbar from "./components/Partials/Navbars/Navbar";
import NavbarTablet from "./components/Partials/Navbars/NavbarTablet";
import NavbarMobile from "./components/Partials/Navbars/NavbarMobile";
import Footer from "./components/Partials/Footer/Footer";
import AIAssistant from "./components/Partials/AIAssistant/AIAssistant"

import Toast from "primevue/toast";

export default {
/* Name */
name: "App",
inject: ["mq"],
/* Components */
components: {
  Footer,
  Navbar,
  NavbarTablet,
  NavbarMobile,
  Sidebar,
  Toast,
  AIAssistant
},
/* Computed */
computed: {
  showNavbar() {
    if (this.$route.path === "/" || this.$route.path.includes('register') || this.$route.path.includes("forgot-password") || this.$route.path.includes("password-reset") || this.$route.name.includes("NotFound")) {
      return false;
    } else {
      return true;
    }
  },
  showFooter() {
    if (this.$route.path === "/" || this.$route.path.includes('register') || this.$route.path.includes("forgot-password") || this.$route.path.includes("password-reset")) {
      return false;
    } else {
      return true;
    }
  },
},
};
</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

*{
  font-family: 'Helvetica', sans-serif;
}
/* General */
[data-theme="dark"] body {
  background-color: #111827;
}
[data-theme="light"] body {
  background-color: #fcfcfc;
}
[data-theme="dark"] .dropdown-menu {
  background-color: #1f2937 !important;
}
[data-theme="dark"] .welcome-text{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .company-name{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .company-role{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .text-left{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .card-title{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .card-body {
  background-color: #1f29374f !important;
  color: #f4f4f4 !important;
  border-radius: inherit;
}
[data-theme="dark"] .p-avatar-text {
  color: #1f2937 !important;
}[data-theme="dark"] .percentage-bar {
  color: #f4f4f4 !important;
}

[data-theme="dark"] .fa-temperature-empty{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .fa-user{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .fa-tag{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .fa-location-dot{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .swiper-slide{
  color: #f4f4f4 !important;
}
[data-theme="dark"] .p-button{
  background-color: #1f2937  !important;
}
[data-theme="dark"] .p-dropdown-trigger{
  background-color: #1f2937  !important;
  border: #3f4b5b !important;
}
[data-theme="dark"] .p-dropdown{
  background-color: #1f2937  !important;
  border: #3f4b5b !important;
}
[data-theme="dark"] .p-inputgroup-addon{
  background-color: #1f2937  !important;
  border: #000 !important;
}


/* Home */
[data-theme="dark"] .home-resume-card {
  background-color: #1f29374f !important;
  color: #f4f4f4 !important;
}
/* Tabke view */
[data-theme="dark"] .main-table {
  background-color: #1f29374f;
}
[data-theme="dark"] .p-card {
  background-color: #1f29374f !important;
}
[data-theme="dark"] .resume-row {
  background-color: #1f29374f !important;
}
[data-theme="dark"] .recent-main-title {
  color: #f4f4f4 !important;
}
[data-theme="dark"] .card {
  background-color: #1f29374f !important;
}
[data-theme="light"] .p-card {
  background-color: #fff !important;
}
[data-theme="light"] .card {
  background-color: #fff !important;
}
[data-theme="dark"] .recent-dashboard {
  background-color: #1f29374f !important;
}
[data-theme="dark"] .details-asset-title {
  background-color: #1f29374f !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .main-details-info {
  background-color: #1f29374f !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .details-actions-div {
  background-color: #1f29374f !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .group-content {
  background-color: #1f29374f !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .team {
  background-color: #1f29374f!important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .open-group-title {
  color: #f4f4f4 !important;
}
[data-theme="dark"] .land {
  fill: #111827 !important;
}

.p-column-title{
  font-size: 1em !important;
  font-weight: 500 !important;
}


/* Investigations */
[data-theme="dark"] .node-default-style{
  background-color: #464e5a  !important;
}
[data-theme="dark"] .investigate-details{
  background-color: #464e5a  !important;
}
[data-theme="dark"] .investigate-edge-options{
  background-color: #464e5a  !important;
}
[data-theme="dark"] .vue-flow__edge-textbg{
  background-color: #464e5a00 !important;
}
[data-theme="dark"] .creator-collapsed-investigate{
  background-color: #464e5a  !important;
}
[data-theme="dark"] .general-node-options{
  background-color: #464e5a  !important;
}

[data-theme="dark"] .timeline-title{
  color: #f4f4f4  !important;
}


/* Sources */
[data-theme="dark"] .source-title {
  color: #f4f4f4 !important;
}
/* Details tab */
[data-theme="dark"] .details-title {
  color: #f4f4f4 !important;
}
[data-theme="dark"] p {
  color: #f4f4f4 !important;
}
[data-theme="dark"] .detail-name {
  color: #f4f4f4 !important;
}
[data-theme="dark"] .top-tab-text {
  color: #f4f4f4 !important;
}
[data-theme="dark"] .top-tab-subicon-text {
  color: #f4f4f4 !important;
}
[data-theme="dark"] .id-prefix {
  color: #f4f4f4 !important;
}
[data-theme="light"] .id-prefix {
  color: #000 !important;
}
[data-theme="dark"] .chart-label {
  color: #f4f4f4 !important;
}
/* Top config*/
[data-theme="dark"] .top-config-label {
  color: #f4f4f4 !important;
}
/* Side */
[data-theme="dark"] .main-menu {
  background-color: #1f2937 !important;
}
[data-theme="dark"] .group-item {
  background-color: #1f2937 !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .item {
  background-color: #1f2937 !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] a {
  background-color: #54535700 !important;
  color: #f4f4f4 !important;
}

/* Navbar */
[data-theme="dark"] .navbar {
  background-color: #3c4755 !important;
}
[data-theme="dark"] .form-inline {
  color: #f4f4f4 !important;
}
/* Footer */
[data-theme="dark"] .footer {
  background-color: #1f2937  !important;
  border: none;
}
/* Table */
[data-theme="dark"] .p-datatable .p-datatable-tbody > tr {
  background-color: #1f2937  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #485363  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-datatable .p-datatable-thead > tr > th {
  background-color: #1f2937  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-paginator {
  background-color: #1f2937  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-paginator-current {
  background-color: #1f2937  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-inputtext {
  border: 1px solid #3f4b5b;
  background: #111827  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-multiselect {
  border: 1px solid #3f4b5b;
  background: #111827  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-tabview .p-tabview-nav {
  background: #1f2937  !important;
  color: #e3e4e6 !important;
}

/* Dashboards */
[data-theme="dark"] .choose-chart {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .choose-chart {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .chart-type {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .chart-helper {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .chart-type-selector:hover {
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
}
[data-theme="dark"] .chart-type-selector {
  background: #3c4755  !important;
}
[data-theme="dark"] .col label {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .card h4 {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .dashboard-name {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .vue-grid-item {
  background: #3c4755  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .dashboard-canvas-no-edit {
  background: #1f2937  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-chart {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .canvas {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-tag {
  background: #111827  !important;
  color: #e3e4e6 !important;

}
[data-theme="dark"] .dashboard-canvas {
  background: #1f2937  !important;
  color: #e3e4e6 !important;
  background-size: 40px 40px !important;
  background-image: radial-gradient(circle, #ffffff 1px, rgba(255, 255, 255, 0) 1px) !important;
}
[data-theme="dark"] .p-dialog-content {
  background: #3c4755  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-dialog-footer {
  background: #3c4755  !important;
  color: #e3e4e6 !important;
}
[data-theme="dark"] .p-fileupload-buttonbar {
  background: #111827  !important;
  color: #e3e4e6 !important;
  border-color: #3c4755 !important;
}
[data-theme="dark"] .p-fileupload-content {
  background: #111827  !important;
  color: #e3e4e6 !important;
  border-color: #3c4755 !important;
}

/* profile */
[data-theme="dark"] .profile-info-card {
  color: #e3e4e6 !important;
}

/* config listener */
[data-theme="dark"] .configmenu {
  background: #111827  !important;
  color: #e3e4e6 !important;
  border-color: #3c4755 !important;
}
[data-theme="dark"] .top-user-container {
  background: #111827  !important;
  color: #e3e4e6 !important;
  border-color: #3c4755 !important;
}
[data-theme="dark"] .user-name-manage-roles {
  color: #e3e4e6 !important;
}
[data-theme="dark"] .task-table-title {
  color: #e3e4e6 !important;
}

/* Logs */ 
[data-theme="dark"] .selectable-field {
  color: #e3e4e6 !important;
}
[data-theme="dark"] label {
  color: #e3e4e6 !important;
}
[data-theme="dark"] hr {
  color: #e3e4e6 !important;
}

/* Chat */
[data-theme="dark"] .chatbox{
  background-color: #373f4b !important;
}
[data-theme="dark"] .chatbox__body__message--left{
  background-color: #4f5864 !important;
  color: #f4f4f4 !important;
}
[data-theme="dark"] .chat-bot-tag{
  background-color: #41bfec!important;
  color: #f4f4f4 !important;
}


#app {
  height: auto;
  width: 100vw;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>