<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <div class="ml-1 row search-integration-row">
        <div class="col-2 p-0 m-0">
          <InputText :placeholder="$t('search')" class="mb-2" v-model="search"></InputText>
        </div>
        <MultiSelect class="col-6 p-0 m-0" v-model="selectedCategories" :placeholder="$t('category')"  :options="categories" optionLabel="name" display="chip" @change="updateSelected()"></MultiSelect>
        <!--
          <div class="form-check ml-4 checkbox" v-for="category in categories" :key="category">
            <input class="form-check-input" type="checkbox" value="" :id="category.name" v-model="category.active"
              @change="addOrRemove(category.name)">
            <label class="form-check-label" :for="category.name">
              {{ category.name }}
            </label>
          </div>
        -->
        <div class="col-4 p-0 m-0">
          <Button :label="$t('createNewIntegration')" class="custom-btn mr-4" icon="pi pi-plus" @click="$refs.newIntegration.openModal()" style="float:right;" v-if="hasUserPermissionGlobal(user, 'source:create')" />
        </div>
      </div>
      <div class="row">
        <div v-for="source in sources" :key="source" class="source-card-container">
          <Card class="source-card" @click="$router.push({ path: 'source-details', query: { id: source.id } })" style="cursor:pointer;"
            v-if="(source.name.toLowerCase().includes(search.toLowerCase()) && selected.length == 0) || (source.name.toLowerCase().includes(search.toLowerCase()) && selected.includes(source.category))">
            <template #content>
              <div class="source-cont text-center">
                <div class="row source-img-row pl-4 pr-4" v-if="source.logo"><img class="source-img" :src="getSourceLogo(source.logo)"></div>
                <!-- Div with the first character -->
                <div class="row source-img-row pl-4 pr-4" v-else>
                  <div :class="checkChar(source.name.substr(0, 1) )">
                    <p class="default-img-letter">{{ source.name.substr(0, 1) }}</p>
                  </div>
                </div>
                <div class="row">
                  <h6 class="source-title mt-4 pl-4 pr-4 text-center">
                    <strong v-if="source.name.length > 20">{{ source.name.substr(0, 20) }}...</strong>
                    <strong v-else>{{ source.name }}</strong>
                  </h6>
                  <h6 class="source-title text-center explore-label"
                    @click="$router.push({ path: 'source-details', query: { id: source.id } })">
                    <small>{{ $t('explore') }}</small>
                  </h6>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <!--============================================================================== newIntegration ==============================================================================-->
    <Modal ref="newIntegration">
      <template #header>
      </template>
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ createNewIntegrationStepTitle }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-4" v-if="createNewIntegrationStep == 0">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_data_source_name" :placeholder="$t('integrationName')"
              style="width:100%;">
            </InputText>

            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_data_source_description" :placeholder="$t('integrationDescription')"
              style="width:100%;">
            </Textarea>

            <label for="" class="pl-0 pb-0 mb-0">{{ $t('category') }}</label>
            <Dropdown v-model="selectedCategory" editable :placeholder="$t('category')" style="width:100%;" :options="categories" optionLabel="name"></Dropdown>

            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('integrationLogo') }}</label>
            <FileUpload name="demo[]" @uploader="onAdvancedUpload($event)" :multiple="false" accept="image/*"
              :max-file-size="1000000" customUpload :showUploadButton="true">
              <template #content="{ uploadedFiles }">
                <ul v-if="uploadedFiles && uploadedFiles[0]">
                  <li v-for="file of uploadedFiles" :key="file">{{ file.name }} - {{ file.size }} bytes</li>
                </ul>
              </template>
              <template #empty>
                <p>Drag and drop files to here to upload.</p>
              </template>
            </FileUpload>
            <p v-if="!(selectedIntakeMethodsIds.length>=1)" style="color:#ec635f">*{{ $t('noConfigurationWarningForIntegrations') }}</p>
          </div>
          <div class="col-12 p-4" v-if="createNewIntegrationStep == 1">
            <div class="row ml-1 mr-1">
              <label for="" class="pl-0 pb-0 mb-0"><strong>{{ $t('selectIntakeMethods') }}</strong></label>
            </div>
            <div class="row ml-1 mr-1">
              <div class="col-12 p-0 m-0">
                <div class="card mt-2" v-for="intake in selectedIntakeMethods" :key="intake">
                <div class="card-body">
                  <InputSwitch v-model="intake.active" class="mt-1 mr-1" style="position: absolute; top:0; right:0; z-index:5" @click="updateSelectedIntakeMethods(intake.id)"/>
                  <h6 class="card-title mb-0">{{ intake.name }}</h6>
                  <p class="card-text m-0"><em>{{intake.nifi_listener}}</em></p>
                  <p class="m-0"><small>{{intake.description}}</small></p>
                </div>
              </div>
              </div>
              <div class="card mt-2 text-center pt-4 pb-4" @click="$refs.newIntakeMethod.openModal()" style="cursor:pointer">
                <i class="pi pi-plus"></i>
              </div>
              <p v-if="!(selectedIntakeMethodsIds.length>=1)" style="color:#ec635f">*{{ $t('noConfigurationWarningForIntegrations') }}</p>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom-save" icon="pi pi-chevron-right" iconPos="right"
          :label="$t('continueWithIntakeConfiguration')" @click="stepFront"
          v-if="createNewIntegrationStep==0" :disabled="(selectedCategory!= null && new_data_source_name != '' && new_data_source_description!='')? false:true"></Button>
        <Button class="btn-custom-save" icon="pi pi-chevron-left" iconPos="left" :label="$t('goback')"
          @click="stepBack" v-if="createNewIntegrationStep>0"></Button>
        <Button :disabled="(selectedCategory!= null && new_data_source_name != '' && new_data_source_description!='')? false:true" class="btn-custom" :label="$t('save')" @click="addNewIntegration(new_data_source_name,new_data_source_description)" v-tooltip.top="$t('continueLaterConfiguration')"></Button>
      </template>
    </Modal>

    <!--============================================================================== newIntakeMethod ==============================================================================-->
    <Modal ref="newIntakeMethod">
      <template #header>
      </template>
      <template #body>
        <!--
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-question" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewIntakeMethod') }}</h5>
          </div>
        </div>
        -->
        <div class="row mt-4">
          <div class="col-6 pt-2">
            <h6 class="mb-0"><strong>{{ $t('newIntakeMethod') }}</strong></h6>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_intake_name" :placeholder="$t('name')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_intake_desc" :placeholder="$t('description')" style="width:100%;" rows="5"></Textarea>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('nifiListener') }}</label>
            <InputText type="text" v-model="new_intake_nifi" :placeholder="$t('nifiListener')" style="width:100%;"> <!-- Cambiar a un dropdown en el futuro -->
            </InputText>

          </div>
          <div class="col-6 pt-2">
            <h6 class="mb-0"><strong>{{ $t('configurationSchemaParams') }}</strong></h6>
            <h6 class="mb-0" style="color:#ec635f" v-if="show_params_error">{{ $t('atLeastOneParamNeeded') }}</h6>

            <div class="configuration-schema-params-card mt-2">
              <div class="card p-2 pb-0" style="background-color: #f7f7f7 !important;">
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
                <InputText type="text" v-model="new_param_name" :placeholder="$t('intakeSchemaName')" style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('description') }}</label>
                <InputText type="text" v-model="new_param_desc" :placeholder="$t('intakeSchemaDescription')"
                  style="width:100%;">
                </InputText>
                <label for="" class="pl-0 pb-0 mb-0">{{ $t('type') }}</label>
                <Dropdown v-model="selectedType" :placeholder="$t('type')" style="width:100%;" :options="types"
                  optionLabel="name"></Dropdown>
                <Button class="btn-custom-save mt-2" :label="$t('add')"
                  @click="addNewParam(new_param_name, new_param_desc, selectedType)"
                  style="float:right"></Button>
              </div>
              <ScrollPanel style="width: 100%; height:15em" class="card mt-2">
                <div class="no-params" v-if="new_schema_params_array.length == 0">
                  <p class="text-center mt-2 mb-0">{{ $t('noParams') }}</p>
                </div>
                <div class="card p-2 m-2" v-for="param in new_schema_params_array" :key="param">
                  <i class="pi pi-times mt-1 mr-1" @click="deleteParamFromIntakeMethodConf(param.name)"
                    style="position: absolute; top:0; right:0; cursor:pointer; font-size: small;"></i>
                  <div class="row m-0">
                    <p class="m-0 pr-2" style=" font-size: small;"><strong>{{ param.name.toUpperCase() }}</strong></p>
                  </div>
                  <div class="row m-0">
                    <p class="m-0 pr-2" style=" font-size: small;"><i>{{ param.type }}</i></p>
                  </div>
                  <div class="row m-0">
                    <p class="m-0 pr-2" style=" font-size: small;">{{ param.description }}</p>
                  </div>
                </div>
              </ScrollPanel>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button class="btn-custom" :label="$t('save')"
          @click="addNewIntakeMethod(new_intake_name, new_intake_desc, new_intake_nifi, new_intake_conf_schema_name, new_schema_params)" icon="pi pi-plus"></Button>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { random } from "gsap";
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>

.no-params{
  font-size:small;
  color: #ec635f;
}

.configuration-schema-params-card  .p-scrollpanel-wrapper{
  height: 120em !important;
  padding-bottom: 0em !important;
}

.search-integration-row {
  display: block ruby;
}

.checkbox {
  width: auto;
}

.source-card-container {
  padding: 0;
  margin: 0;
  display: contents;
}

.explore-label {
  color: #ec635f;
  font-size: 0.9em;
  cursor: pointer;
}

.explore-label:hover {
  color: #ec635f;
  font-size: 0.9em;
  text-decoration: underline;
}

.p-card {
  width: 100%;
  display: inline-block;
}

.source-card {
  width: 12em;
  height: 12em;
  margin: 1em;
}

.source-cont {
  padding-top: 2em;
}

.source-img {
  width: 100%;
  max-height: 4em;
  object-fit: contain;
}

.default-img-v1 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background: #ec635f !important;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-v2 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background-color: #9fd6dd;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-v3 {
  height: 4em;
  width: 4em;
  line-height: 3.8em;
  margin: auto;
  background-color: #ffce36;
  color: white;
  border-radius: 500px;
  text-transform: uppercase;
  font-size: 1em;
  text-align: center;
  padding: 0;
}

.default-img-letter {
  margin: 0;
  font-size: 2em;
}

.source-img-row {
  height: 4em;
}

.form-check-input:checked {
  background-color: #ec635f !important;
  border-color: #ec635f !important;
}
</style>
